<template>
  <div class='flex flex-col lg:flex-row border-t border-b lg:border'>
    <div class='px-2 lg:px-4 py-4 lg:flex-grow'>
      <h4 class='uppercase text-xs text-gray-800 leading-5 lg:leading-6 text-right float-right'>{{abstract.abstractNo}}</h4>
      <h2 class='w-4/5 font-base leading-5 lg:leading-6 text-base text-gray-700 mb-2'>{{abstract.title}}</h2>
      <h3 class='w-4/5 font-medium leading-5 lg:leading-6 text-base text-gray-900'>{{authorsString}}</h3>
      <v-clamp
        v-if='hasAffiliation'
        class='w-4/5 font-base leading-5 text-sm text-gray-900 mb-2 whitespace-pre-line'
        :max-lines='2'>
         {{authorsAffiliations}}
      </v-clamp>
      <h3 class='font-light text-gray-700'>{{tagsString}}</h3>
    </div>
  </div>
</template>

<script>
import VClamp from 'vue-clamp'
export default {
  name: 'AbstractsRow',
  components: {
    VClamp
  },
  props: {
    abstract: {
      type: Object,
      required: true
    }
  },
  computed: {
    tagsString () {
      if (this.abstract && this.abstract.conferenceTags) {
        let tagList =  this.abstract.conferenceTags.map(tag => {
          return tag.list
        }).flat()
        return tagList.map(tag => {
          return `#${tag}`
        }).join(' ')
      } else {
        return ''
      }
    },
    authorsString () {
      if (this.abstract && this.abstract.authors) {
        return this.abstract.authors.map(author => `${author.firstName} ${author.lastName}`).join(', ')
      } else {
        return ''
      }
    },
    hasAffiliation () {
      return this.abstract.authors.map(author => `${author.affiliation}`).join(', ')
    },
    authorsAffiliations () {
      return this.abstract.authors.map(author => `${author.affiliation}`).join(', ')
    },
  },
}
</script>
