<template>
  <div class='flex flex-row lg:flex-col justify-start border-b lg:border-none'>
    <div class='relative flex-grow lg:flex-grow-0 border-r lg:border-none'>
      <input 
        type='text'
        class='w-full px-2 py-3 border-none lg:border-solid lg:border border-gray-300 lg:rounded-lg text-sm'
        placeholder='Search by title/name/#'
        v-model='searchString'
        @keyup.enter='getSearchedAbstracts' />
      <button
        v-if='searchString'
        @click='resetSearchString'>
        <x-icon 
          class='absolute text-gray-300 h-5'
          style='top: 0.875rem; right: 0.5rem;' />
      </button>
      <search-icon
        v-else
        class='absolute text-gray-300 h-5'
        style='top: 0.875rem; right: 0.5rem;' />
    </div>
    <abstract-filters />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { SearchIcon, XIcon } from '@vue-hero-icons/outline'
import AbstractFilters     from '@/components/AbstractFilters.vue'

export default {
  name: 'AbstractControls',
  components: {
    AbstractFilters,
    SearchIcon,
    XIcon
  },
  data () {
    return {
      searchString: '',
    }
  },
  methods: {
    ...mapActions('abstracts', [
      'getAllAbstracts',
    ]),
    getSearchedAbstracts () {
      this.getAllAbstracts(this.searchString)
    },
    resetSearchString () {
      this.searchString = ''
      this.getSearchedAbstracts()
    }
  }
}
</script>
