<template>
  <div class='mt-4'>
    <h2 class='uppercase font-semibold'>{{singleFilter.name}}</h2>
    <label v-for="(keyword, index) in singleFilter.list"
      :key='`${singleFilter.name}-${keyword}-${index}`'
      class='py-1 text-sm text-gray-500 flex flex-row items-center cursor-pointer hover:bg-blue-50'>
      <input type="checkbox" class='mr-2'
             :value='keyword'
             v-model='componentFilterValues'>
      <span>{{ keyword }}</span>
    </label>
  </div>
</template>

<script>

export default {
  name: 'SingleFilter',
  props: ['singleFilter'],
  data () {
    return {
      componentFilterValues: []
    }
  },
  watch: {
    componentFilterValues: {
      handler: function (newVal) {
        this.$emit('update-filter-values', {
          name:       this.singleFilter.name,
          objectKey:  this.singleFilter.objectKey,
          list:       newVal,
        })
      }
    }
  },
  mounted () {
    for (const [key, value] of Object.entries(this.$route.query)) {
      if (this.singleFilter.name.toLowerCase() === key.toLowerCase()) {
        let filterValue = this.singleFilter.list.find(ele => ele.toLowerCase() === value.toLowerCase())
        if (filterValue) {
          this.componentFilterValues.push(filterValue)
        }
      }
    }
  },
}
</script>
