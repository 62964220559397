<template>
  <div class='pt-0 lg:pt-12'>
    <div class='flex flex-col lg:flex-row justify-between items-start gap-x-6 gap-y-4'>
      <abstract-controls class='w-full lg:w-48 flex-grow-0 flex-shrink-0 px-2 lg:px-0' />
      <div class='w-full flex-grow text-sm text-gray-600 flex flex-col gap-y-2 justify-start'>
        <abstract-row
          v-for='abstract in filteredAbstracts'
          :key='`abstract-${abstract.id}`'
          :abstract='abstract'
          class='cursor-pointer opacity-100 lg:opacity-90 hover:opacity-100 hover:shadow-md lg:rounded-lg'
          @click.native='goToSingleAbstract(abstract)'>
        </abstract-row>
        <div v-if='emptyFilteredAbstracts'
          class='flex-grow p-16 rounded-md bg-gray-100 text-center'>
          <p class='text-gray-500 text-sm'>No abstracts found.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AbstractRow    from '@/components/AbstractRow.vue'
import AbstractControls from '@/components/AbstractControls.vue'

export default {
  name: 'Abstracts',
  components: {
    AbstractRow,
    AbstractControls,
  },
  computed: {
    ...mapGetters('abstracts', [
      'filteredAbstracts',
      'hasAbstracts',
    ]),
    emptyFilteredAbstracts () {
      return this.filteredAbstracts.length === 0
    },
  },
  methods: {
    ...mapActions([
      'hideFullpageLoadingIndicator',
      'showFullpageLoadingIndicator',
    ]),
    ...mapActions('abstracts', [
      'getAllAbstracts',
    ]),
    ...mapActions('filters', [
      'getFilters',
    ]),
    goToSingleAbstract (abstract) {
      console.log('selected abstract: ', abstract)
      this.$router.push({name: 'Abstract', query: { abstract_id: abstract.id }})
    },
  },
  beforeDestroy () {
    this.hideFullpageLoadingIndicator()
  },
  mounted () {
    if (!this.hasAbstracts) {
      this.showFullpageLoadingIndicator()
      this.getAllAbstracts().then(() => {
        this.hideFullpageLoadingIndicator()
      })
    }
    this.getFilters()
  }
}
</script>
